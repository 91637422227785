import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import { FormCard, ct } from "@mooovex/components";
import { supabase } from "@mooovex/supabase-client";
import { useNavigate, useSearchParams } from "@solidjs/router";
import { Component, Show, createSignal } from "solid-js";
import { z } from "zod";
type Props = {};

export const OTPPage: Component<Props> = (props) => {
  const [params] = useSearchParams<{ email: string }>();
  const [loadingStatus, setLoadingStatus] = createSignal<"ready" | "error" | "loading">("ready");
  const navigate = useNavigate();
  const schema = z.object({ otp: z.string().min(6).max(6) });
  const { form, errors, isValid } = createForm<z.infer<typeof schema>>({
    extend: [validator({ schema })],
    onSubmit: async ({ otp }) => {
      setLoadingStatus("loading");
      try {
        const { error } = await supabase.auth.verifyOtp({ type: "recovery", token: otp, email: params.email! });
        if (error) throw error;
        navigate("/user?changePassword=true");
        setLoadingStatus("ready");
      } catch (error) {
        setLoadingStatus("error");
      }
    },
  });

  return (
    <>
      <FormCard>
        <form ref={form}>
          <div class="form-floating mb-1">
            <input
              ref={(ref) => ref.focus()}
              name="otp"
              type="text"
              class="form-control"
              classList={{ "is-invalid": !!errors().otp?.length }}
              placeholder=""
            />
            <label for="otp" class="form-label">
              {ct.account.sixdigitcode()}
            </label>
            <div class="text-danger" classList={{ invisible: !errors().otp?.length }}>
              {errors().otp?.[0] ?? "-"}
            </div>
          </div>
          <Show when={loadingStatus() === "error"}>
            <div class="alert alert-danger">{ct["account.codeVerifyFailed"]()}</div>
          </Show>
          <button
            type="submit"
            class="btn btn-lg btn-primary w-100 d-flex justify-content-center align-items-center gap-2"
            disabled={!isValid()}
          >
            <Show when={loadingStatus() === "loading"}>
              <div class="spinner-border spinner-border-sm" />
            </Show>
            {ct["common.continue"]()}
          </button>
        </form>
      </FormCard>
    </>
  );
};
